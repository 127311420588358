import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';

export const addStart = () => {
    return{
        type: actionTypes.ADD_DEVICE_START
    }
};

export const addSuccess = () => {
    return{
        type: actionTypes.ADD_DEVICE_SUCCESS
    }
}

export const addFail = () => {
    return{
        type: actionTypes.ADD_DEVICE_FAIL
    }
}

export const addDevExec = (deviceId, deviceName, username, email, deviceKind) => {
    return dispatch => {
        dispatch(addStart());
        const addData = {
            deviceId: deviceId,
            deviceName: deviceName,
            username: username,
            email: email,
            deviceKind: deviceKind
        }
        axios.post('/addDevices', addData)
            .then(response => {
                
                dispatch(addSuccess())
            })
            .catch(err => {
                dispatch(addFail(err))
            })
    }
}