import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuxDash from '../../hoc/Aux_folder/Aux_file';
import * as actions from '../../store/actions/index';
import axios from '../../axios-dashboard';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import DashboardElements from '../../components/Dashboard/DashboardElements';
import DashboardPlots from '../../components/Dashboard/DashboardPlots/DashboardPlots';
import Spinner from '../../components/UI/Spinner/Spinner';
import { updateObject } from '../../share/utility';
import styles from '../../css/style.css';

class DashboardBulder extends Component{

    componentDidMount(){
        this.props.onDashboardInit();
        this.props.onDashboardRetrieved(localStorage.getItem('email'));
        this.props.onPlotReset();
    }

    changeCardData = (fromServer) => {
        if(fromServer.length > 0){            
            if(fromServer !== 'Estas conectado al servidor'){                          
                const device_id = fromServer[0].deviceId;
                const device_name = fromServer[0].deviceName;
                let newArray = [...this.props.deviceArray];
                const elementsIndex = this.props.deviceArray.findIndex(element => element.device_id === device_id);     
                if(elementsIndex >= 0){                    
                    newArray[elementsIndex].value = fromServer[0].value;
                    newArray[elementsIndex].id = fromServer[0].insertId;
                    newArray[elementsIndex].timestamp = fromServer[0].timestamp;
                    updateObject(this.props.deviceArray, newArray);
                }                
                if (this.props.optionsArray.title.text===device_name){                    
                    this.props.optionsArray.data[0].dataPoints.push({
                        label: fromServer[0].timestamp,
                        y: Number(fromServer[0].value),
                        x: this.props.optionsArray.data[0].dataPoints.length
                    });
                }                
            }else{
                console.log('Sin datos')                
            }            
        }
    }

    showPlotsHandler = (name) => {        
            this.props.onPlotsRetrieved(name);
    }
    
    render(){
        let dashElem = (
            <Spinner />
        );

        let showDevicePlots = (
            <div className={styles.NoPlot}>
                <h1>Sin plots para mostrar</h1>
            </div>
        )
        
        if(this.props.fromServer){                 
            this.changeCardData(this.props.fromServer);            
        }

        if (this.props.loadPlots) {
            showDevicePlots = (
                <Spinner />
            )            
        }

        if(this.props.showPlot){
            showDevicePlots = (                                                       
                <DashboardPlots 
                    options={this.props.optionsArray} 
                    numero={this.props.optionsArray.data[0].dataPoints.length}/>
            )
        }

        if(!this.props.loadDashboard){
            dashElem = (
                <DashboardElements device={this.props.deviceArray} 
                numberDevices={this.props.deviceLength}
                showPlotsAction={this.showPlotsHandler}/>
            )
        }        
        
        return(
            <AuxDash>
                <div className={styles.Dashboard}>
                    <div className={styles.ContainerElements}>
                        {dashElem}     
                    </div>                    
                    <hr></hr>
                    <div className={styles.ContainerPlots}>                                              
                        {showDevicePlots}
                    </div>                    
                </div>
            </AuxDash>
        )}
}

const mapStateToProps = state => {
    return{
        deviceArray: state.dashItem.deviceArray,
        deviceLength: state.dashItem.deviceLength,
        loadDashboard: state.dashItem.loadDashboard,
        optionsArray: state.dashPlot.optionsArray,
        loadPlots: state.dashPlot.loadPlots,
        showPlot: state.dashPlot.showPlot,
        fromServer: state.socket.fromServer                       
    };
}

const mapDispatchProps = dispatch => {
    return{
        onDashboardInit: () => dispatch(actions.dashItemStart()),
        onDashboardRetrieved: (user) => dispatch(actions.getDashItem(user)),
        onPlotsRetrieved: (nameDevice) => dispatch(actions.getPlotsItem(nameDevice)),
        onPlotInit: () => dispatch(actions.dashPlotStart()),
        onPlotReset: () => dispatch(actions.dashPlotReset()),
               
    };
}

export default connect(mapStateToProps, mapDispatchProps)(withErrorHandler(DashboardBulder, axios));