import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    errorDevice: false,
    loadingDevice: false
}

const devicesStart = (state, action) => {
    return updateObject(state, {
        errorDevice: null, 
        loadingDevice: true
    })
}

const deviceSuccess = (state, action) => {
    return updateObject(state, {
        deviceArray: action.deviceArray,
        deviceLength: action.deviceLength,        
        errorDevice: false, 
        loadingDevice: false
    })
}

const deviceFail = (state, action) => {
    return updateObject(state, {
        errorDevice: true, 
        loadingDevice: false
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.DEVICE_START:
            return devicesStart(state, action);
        case actionTypes.DEVICE_SUCCESS:
            return deviceSuccess(state, action);
        case actionTypes.DEVICE_FAIL:
            return deviceFail(state, action);
        default:
            return state;
    }
}

export default reducer;