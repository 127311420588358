import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';

export const devicesStart = () => {
    return {
        type: actionTypes.DEVICE_START
    }
}

export const deviceSuccess = (deviceArray, deviceLength) => {
    return {
        type: actionTypes.DEVICE_SUCCESS,
        deviceArray: deviceArray,
        deviceLength: deviceLength
    }
}

export const deviceFail = (error) => {
    return {
        type: actionTypes.DEVICE_FAIL,
        error: error
    }
}

export const getDeviceItem = (user) => {
    return dispatch => {
        dispatch(devicesStart());        
        axios.post('/getDeviceSpecific', {email: user})
            .then(response => {
                const fetchedDevices = [];
                const numberGrid = [];         
                for(let i=0; i < response.data.message.length; i++){
                    fetchedDevices.push({                        
                        device_id: response.data.message[i].device_id,
                        device_name: response.data.message[i].device_name,
                        active: response.data.message[i].activado,
                        total: response.data.message[i].total,
                        web_name: response.data.message[i].web_name,                                                
                        kind: response.data.message[i].kind,
                        id: response.data.message[i].id,
                        timestamp: response.data.message[i].timestamp
                    })
                    numberGrid.push(i);
                }
                dispatch(deviceSuccess(fetchedDevices, numberGrid))
            })
            .catch(error => {
                dispatch(deviceFail(error))
            })
    }
}