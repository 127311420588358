import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import authReducer from './store/reducers/auth';
import dashItemReducer from './store/reducers/dashItems';
import dashPlotReducer from './store/reducers/dashPlots';
import socketReducer from './store/reducers/socket';
import addReducer from './store/reducers/addDevice';
import devicesReducer from './store/reducers/devices';
import homePageReducer from './store/reducers/homePage';
// import loggerHandler from './store/Middleware/report_logger';
// import crashReporter from './store/Middleware/report_crash';
import socketIOMiddleware from './store/Middleware/socket_Middleware';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
const rootReducer = combineReducers({
  auth: authReducer,
  dashItem: dashItemReducer,
  dashPlot: dashPlotReducer,
  socket: socketReducer,
  addDevice: addReducer,
  devices: devicesReducer,
  homePage: homePageReducer
});


    // crashReporter,
    // loggerHandler, 
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk,
    socketIOMiddleware('http://54.70.120.203:3800'))
));

const app = (
  <Provider store={store}>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();