import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    errorAdd: false,
    loadingAdd: false,
}

const addStart = (state, action) => {
    return updateObject(state, {
        errorAdd: null, loadingAdd: true
    })
}

const addSuccess = (state, action) => {
    return updateObject(state, {
        errorAdd: null, loadingAdd: false
    })
}

const addFail = (state, action) => {
    return updateObject(state, {
        errorAdd: true, loadingAdd: false
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.ADD_DEVICE_START:
            return addStart(state, action);
        case actionTypes.ADD_DEVICE_SUCCESS:
            return addSuccess(state, action);
        case actionTypes.ADD_DEVICE_FAIL:
            return addFail(state, action);
        default:
            return state;
    }
}

export default reducer;