import React, { Component } from 'react';
import utilities from '../../../css/utilities.css';
import Input from '../Input/Input';
import { updateObject, checkValidity } from '../../../share/utility';
import Button from '../Button/Button';
import axios from '../../../axios-dashboard';

class Modal extends Component {
    constructor(props){
        super(props);
        this.state = {
            alreadyChanged: false,
            controls:{
                deviceName:{
                    elementType: 'input',
                    elementConfig: {
                        type: 'textarea',
                        placeholder: 'Device Name'
                    },
                    value: '',
                    validation:{
                        require: true,
                        minLength: 10
                    },
                    valid: false,
                    touched: false
                },
                deviceKind:{
                    elementType: 'select',
                    elementConfig: {
                        options: [{value:'Nada', displayValue:'Selecciona el tipo de dispositivo'},
                            {value:'Distancia', displayValue:'Distancia'},
                            {value:'Flujo', displayValue:'Flujo'},
                            {value:'Estado', displayValue:'Estado'}
                        ]
                    },
                    value: 'Nada',
                    validation:{
                    },
                    valid: true,                
                },
                active:{
                    elementType: 'select',
                    elementConfig: {
                        options: [{value:'1', displayValue:'activar'},
                            {value:'0', displayValue:'desactivado'},
                        ]
                    },
                    value: '1',
                    validation:{
                    },
                    valid: true,                
                }                
            }
        }
    }

    inputChangedHandler = (event, controlName) => {
        const updateControls = updateObject(this.state.controls, {
            [controlName]: updateObject(this.state.controls[controlName], {
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            })
        });
        this.setState({controls: updateControls});        
    }

    submitHandler = (event) => {
        event.preventDefault();
        const addData = {
            device_id: this.props.device_id,
            device_name: this.state.controls.deviceName.value,
            kind: this.state.controls.deviceKind.value,
            active: this.state.controls.active.value
        }        
        axios.post('/updateDevice', addData)
            .then(response => {                
                if (response.data.message === 'success' ) { 
                    this.setState({alreadyChanged: true})
                    alert('Has cambiado el dispositivo')                                                                               
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    render(){          
        let attachedClasses = [utilities.modal, utilities.displayblock];
        if (!this.props.show) {
            attachedClasses = [utilities.modal, utilities.displaynone];
        }
        const showhideClassName = attachedClasses.join(' ');
        const formElementArray = [];
        for(let key in this.state.controls){
            formElementArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }
        let form = formElementArray.map(formElement => (
            <Input 
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                label={formElement.config.elementConfig.placeholder}
                changed={(event) => this.inputChangedHandler(event, formElement.id)}
            />
        ));    
        
        let buttonFunc = (
            <Button btnType="Element">Update</Button>
        )
        if (this.state.alreadyChanged) {
            buttonFunc = (
                <p>Haz actualizado el dispositivo!!!</p>
            )            
        }
        return(
            <div className={showhideClassName}>                
                <section className={utilities.modalmain}>
                    <p>{this.props.device_id}</p>
                    <form onSubmit={this.submitHandler}>
                        {form}
                        {buttonFunc}
                        {/* <Button btnType="Element">Update</Button> */}
                    </form>    
                    <Button btnType="Logout" clicked={this.props.handleClose}>Cancel</Button>
                </section>
            </div>
        )
    }
}

export default Modal;