import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';

export const dashItemStart = () => {
    return {
        type: actionTypes.DASH_START
    }
}

export const dashItemSuccess = (deviceArray, deviceLength) => {
    return{
        type: actionTypes.DASH_SUCCESS,
        deviceArray: deviceArray,
        deviceLength: deviceLength
    }
}

export const dashItemFail = (error) => {
    return{
        type: actionTypes.DASH_FAIL,
        error: error
    }
}

export const getDashItem = (user) => {
    return dispatch => {
        dispatch(dashItemStart());        
        axios.post('/getUserDevices', {email: user})
            .then(response => {
                const fetchedDevices = [];
                const numberGrid = [];         
                for(let i=0; i < response.data.message.length; i++){
                    fetchedDevices.push({
                        id: response.data.message[i].id,
                        device_id: response.data.message[i].device_id,
                        device_name: response.data.message[i].device_name,
                        value: response.data.message[i].value,
                        timestamp: response.data.message[i].timestamp,
                        active: response.data.message[i].active,
                        device_web_name: response.data.message[i].device_webname,
                        kind: response.data.message[i].device_kind
                    })
                    numberGrid.push(i);
                }
                dispatch(dashItemSuccess(fetchedDevices, numberGrid));
                if (response.data.message.length > 0) {
                    dispatch(updateSocket(user));
                }
            })
            .catch(error => {
                dispatch(dashItemFail(error))
            })
    }
}

export const updateSocket = (email) => {
        const updateSocket = {
            email: email,
            socketId: localStorage.getItem('connectID')
        }
        axios.post('/updateDeviceSocket', updateSocket)
            .then(response => {
                console.log('Success')
            })
            .catch(error => {
                console.log('error')
            })
}