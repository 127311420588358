import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuxFileDash from '../Aux_folder/Aux_file';
//import style from '../../css/style.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import * as actions from '../../store/actions/index';

class Layout extends Component{
    
    componentDidMount(){        
        this.props.onTryAuthSignup();          
      }

    render(){  
        let usernameTitle = null;
        if(this.props.isAuthenticated){
            usernameTitle = this.props.username;
        }              
        return(
            <AuxFileDash>
                <body>                    
                    <Toolbar username = {usernameTitle}/>                                  
                    <main>                    
                        {this.props.children}
                    </main>
                </body>
            </AuxFileDash>
        )
    }
}

const mapToState = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        username: state.homePage.username
    }
}

const mapDispatchToProps = dispatch => {  
    return{
      onTryAuthSignup: () => dispatch(actions.authCheckState())
    }
  }

export default connect(mapToState,mapDispatchToProps)(Layout);