import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    optionsArray: {
        title: {
            text: 'Poseidon Project'
        },
        data:[{
            type: 'line',
            dataPoints: [ ]
        }]
    },
    oldOptions: {},
    loadPlots: false,
    errorLoad: false,
    showPlot: false,    
}

const dashPlotReset = (state, action) => {
    return updateObject(state, {
        loadPlots: false,
        errorLoad: false,
        showPlot: false        
    })
}

const dashPlotStart = (state, action) => {
    return updateObject(state, {
        loadPlots: true,
        errorLoad: false,
        showPlot: false})
}

const dashPlotSuccess = (state, action) => {
    return updateObject(state,{
        optionsArray: action.optionsArray,        
        loadPlots: false,
        errorLoad: false,
        showPlot: true,         
    });
}

const dashPlotUpdate = (state, action) => {
    return updateObject(state,{
        optionsArray: action.optionsArray,
        loadPlots: false,
        errorLoad: false,
        showPlot: true,         
    });
}

const dasPlotFail = (state, action) => {
    return updateObject(state, {
        errorLoad: action.error,
        loadPlots: false
    });
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.PLOTS_RESET:
            return dashPlotReset(state, action);
        case actionTypes.PLOTS_START:
            return dashPlotStart(state, action);
        case actionTypes.PLOTS_SUCCESS:
            return dashPlotSuccess(state, action);
        case actionTypes.PLOTS_UPDATE:
            return dashPlotUpdate(state, action);
        case actionTypes.PLOTS_FAIL:
            return dasPlotFail(state, action);
        default:
            return state;
    }
}

export default reducer;