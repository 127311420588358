import React, { Component } from 'react';
import AuxFile from '../Aux_folder/Aux_file';

const withErrorHandler = (WrappedComponent, axios) => {
    return class extends Component{
        state = {
            error: null
        }
        
        componentWillMount(){
            this.reqInterceptor = axios.interceptors.request.use(req => {
                this.setState({error: null});
                return req;
            })
        }

        componentWillUnmount(){
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.request.eject(this.resInterceptor);
        }

        errorConfirmHandler = () => {
            this.setState({error: null})
        }

        render(){
            return(
                <AuxFile>
                    <WrappedComponent {...this.props}/>
                </AuxFile>
            );
        }
    }
}

export default withErrorHandler;