import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';

export const dashPlotReset = () => {    
    return{
        type: actionTypes.PLOTS_RESET,
        loadPlots: false,
        errorLoad: false,
        showPlot: false      
    }
}

export const dashPlotStart = () => {    
    return{
        type: actionTypes.PLOTS_START,
    }
}

export const dashPlotSuccess = (optionsArray) => {    
    return{
        type: actionTypes.PLOTS_SUCCESS,
        optionsArray: optionsArray,
        loadPlots: false,
        errorLoad: false,
        showPlot: true,   
    }
}

export const dashPlotUpdate = (optionsArray) => {
    return{
        type: actionTypes.PLOTS_UPDATE,
        options: optionsArray,        
        loadPlots: false,
        errorLoad: false,
        showPlot: true, 
    }
}

export const dashPlotFail = (error) => {    
    return{
        type: actionTypes.PLOTS_FAIL,
        errorLoad: error
    }
}

export const getPlotsItem = (device) => {    
    return dispatch => {  
        dispatch(dashPlotStart());
        axios.post('/getDataForPlots', {device_id: device, days: 5})
            .then(response => {                                
                const dataPointsAray = [];
                const toOptions = [];
                for(let i=0; i < response.data.plotSize; i++){
                    dataPointsAray.push({
                        label: response.data.message[i].timestamp,
                        y: response.data.message[i].value
                    })
                }                              
                toOptions.push({
                                backgroundColor: "#00ddff",
                                title: 
                                    {text: response.data.message[0].device_name,
                                    fontColor: '#0049b7'
                                    },
                                axisY:{
                                    labelFontColor: "#0049b7"
                                    },
                                axisX:{
                                    labelFontColor: "#0049b7"
                                    }, 
                                                                                                   
                                data:[{
                                    color: "#ff1d58",
                                    type: 'line',                                    
                                    dataPoints: dataPointsAray
                                }]                                
                                });
                dispatch(dashPlotSuccess(toOptions[0]));
            })  
            .catch(error => {
                dispatch(dashPlotFail(error));
            })
    }
}
