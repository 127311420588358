import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (token, userId, email) => {    
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        email: email
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');   
    localStorage.removeItem('connectID') 
    localStorage.removeItem('username') 
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const checkAuthTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}

export const auth = (email, password, isSignup, username) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        }
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + process.env.REACT_APP_KEY_FIREBASE;
        if(!isSignup){
            url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + process.env.REACT_APP_KEY_FIREBASE;
        }
        axios.post(url, authData)
            .then(response => {                
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn *1000);
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.localId);
                localStorage.setItem('email', email);
                if(isSignup){
                    const toServerData = {
                        username: username,
                        email: email,
                        firebase: response.data.idToken,
                    }
                    axios.post('/newUserDevices', toServerData)
                    .then(response => {                        
                        localStorage.setItem('username', username);
                    })
                    .catch(err => {
                        dispatch(authFail(err));
                    })
                }                
                dispatch(authSuccess(response.data.idToken, response.data.localId, email));
                dispatch(checkAuthTimeOut(response.data.expiresIn));                
            })
            .catch(err => {
                dispatch(authFail(err))
            })
    }
}

export const setAuthRedirectPath = (path) => {
    return{
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {    
    return dispatch => {
        const token = localStorage.getItem('token');        
        if(!token){
            dispatch(logout());                      
        }else{
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const userId = localStorage.getItem('userId');
            dispatch(authSuccess(token, userId));
            if(expirationDate <= new Date()){
                dispatch(logout());                
            }else{
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeOut((expirationDate.getTime() - new Date().getTime())/1000));                
            }
        }
    }   
}