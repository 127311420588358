import React from 'react';
import Button from '../../UI/Button/Button';
import styles from '../../../css/style.css';

const deviceElement = (props) => {
    return(
        <div className={styles.DeviceElement}>
                <h1>{props.device.web_name}</h1>
                <p>{props.device.device_name}</p>
                <p>{props.device.kind}</p>
                <p>{props.device.timestamp}</p>
                <p>{props.device.total}</p>
                <p>{props.device.id}</p>
                <p>{props.device.device_id}</p>
                <p>{props.device.active}</p>                
                <Button clicked={e => props.actionButton(props.device.device_id)} btnType="Element">Update</Button>            
                <Button clicked={e => props.deleteAction(props.device.device_id)} btnType="Danger">Delete</Button>
        </div>
    )
}
export default deviceElement;           