import React from 'react';
import DeviceElement from './DeviceElement/DeviceElement';
import styles from '../../css/style.css';

const deviceElements = (props) => {
    const numberDevices = props.numberDevices;
    const deviceElements = props.device;   
    const showModal = props.showModal; 
    const deleteDevice = props.handleDelete;
    return(
        <div className={styles.DeviceElements}>
            {numberDevices.map((value) => (
                <DeviceElement
                key={value} 
                device={deviceElements[value]}
                actionButton={showModal}
                deleteAction={deleteDevice}/>
            ))}            
        </div>
    );
}

export default deviceElements;