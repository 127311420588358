import React from 'react';
import Button from '../../UI/Button/Button';
import styles from '../../../css/style.css'

const dashboardElement = (props) => {
    return(
        <div className={styles.DashboardElement}>
                <h1>{props.device.device_web_name}</h1>
                <p>{props.device.device_name}</p>
                <p>{props.device.value}</p>
                <p>{props.device.timestamp}</p>
                <p>{props.device.kind}</p>
                <Button clicked={e => props.actionButton(props.device.device_id)} btnType="Element">Plot</Button>                
        </div>
    )
}

export default dashboardElement;