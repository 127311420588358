import React,{ Component } from 'react';
import { connect } from 'react-redux';
import NavigationItem from './NavigationItem/NavigationItem';
import * as actions from '../../../store/actions/index';
import Button from '../../UI/Button/Button';

class navigationItems extends Component{
    logoutHandler = (event) => {
        event.preventDefault();
        this.props.onLogoutAction();                
    }

    render(){         
        let navItems = (
            <ul>
                <NavigationItem link='/documentation'>Documentation</NavigationItem>
                <NavigationItem link='/signin'>Login</NavigationItem>                                
                {/* <Button clicked={this.logoutHandler} btnType="LogOut">Logout</Button>  */}
            </ul>
        )
        if(this.props.isAuthenticated){
            navItems = (                
                <ul>
                    <NavigationItem link='/' exact>Home</NavigationItem>  
                    <NavigationItem link='/Dashboard'>Dashboard</NavigationItem>
                    <NavigationItem link='/devices'>Devices</NavigationItem>   
                    <NavigationItem link='/documentation'>Documentation</NavigationItem>                             
                    <Button clicked={this.logoutHandler} btnType="Danger">Logout</Button>                     
                </ul>
            )
        }
        return(
            <div>
                {navItems}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return{
      onLogoutAction: () => dispatch(actions.logout())
    }
  }
  

const mapStateToProps = state => {
    return{
      isAuthenticated: state.auth.token !== null
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(navigationItems);