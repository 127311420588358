import React from 'react';
import DashboardElement from './DasboardElement/DashboardElement';
import styles from '../../css/style.css';

const dashboardElements = (props) => {
    const numberDevices = props.numberDevices;
    const deviceElements = props.device;
    const showPlotElement = props.showPlotsAction;
    return(
        <div className={styles.DashboardElements}>
            {numberDevices.map((value) => (
                <DashboardElement
                key={value} 
                device={deviceElements[value]}
                actionButton={showPlotElement}/>
            ))}            
        </div>
    );
}

export default dashboardElements;