import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {    
    device: {
        id: null,
        active: null,
        device_id: null,
        device_name: null,
        timestamp: null,
        value: null,
        device_web_name: null,
        kind: null
    },
    deviceArray: [],
    deviceLength: [],
    numberDevices: 0,
    loadDashboard: false,
    error: false,
}

const dashItemStart = (state, action) => {
    return updateObject(state, {error: null, loadDashboard: true});
}

const dashItemSuccess = (state, action) => {
    return updateObject(state, {        
        deviceArray: action.deviceArray,
        deviceLength: action.deviceLength,
        error: null,
        loadDashboard: false
    });
}

const dashItemFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loadDashboard: false
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.DASH_START:
            return dashItemStart(state, action);
        case actionTypes.DASH_SUCCESS:
            return dashItemSuccess(state, action);
        case actionTypes.DASH_FAIL:
            return dashItemFail(state, action);
        default:
            return state;
    }
}

export default reducer;
