import React, { Component } from 'react';
import utilities from '../../../css/utilities.css';
import Button from '../Button/Button';
import axios from '../../../axios-dashboard';

class Modal extends Component {
    constructor(props){
        super(props);
        this.state = {
            alreadyChanged: false,
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        const addData = {
            device_id: this.props.device_id,
        }        
        axios.post('/deleteDevice', addData)
            .then(response => {                
                if (response.data.message === 'success' ) { 
                    this.setState({alreadyChanged: true})
                    alert('Has cambiado el dispositivo')                                                                               
                }
            })
            .catch(err => {
                console.log(err)
                alert('Has ocurrido un error')
            })
    }

    render(){          
        let attachedClasses = [utilities.modal, utilities.displayblock];
        if (!this.props.show) {
            attachedClasses = [utilities.modal, utilities.displaynone];
        }
        const showhideClassName = attachedClasses.join(' ');        
        let buttonFunc = (
            <Button btnType="Element" clicked={this.submitHandler}>Delete</Button>
        )
        if (this.state.alreadyChanged) {
            buttonFunc = (
                <p>Haz actualizado el dispositivo!!!</p>
            )            
        }
        return(
            <div className={showhideClassName}>                
                <section className={utilities.modalmain}>
                    <p>{this.props.device_id}</p>                        
                    {buttonFunc}                                        
                    <Button btnType="Logout" clicked={this.props.handleClose}>Cancel</Button>
                </section>
            </div>
        )
    }
}

export default Modal;