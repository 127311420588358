import React from 'react';
import utilities from '../../../css/utilities.css';

const button = (props) => (
    <button 
        className = {[utilities.Button, utilities[props.btnType]].join(' ')}     
        onClick = {props.clicked}>
        {props.children}
    </button>
)

export default button;