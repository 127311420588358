import React from 'react';
import styles from '../../css/style.css';
import dashboardIMG from '../../assets/images/doc_images/home_page.png';
import addDeviceIMG from '../../assets/images/doc_images/add_device.png';
import deviceIMG from '../../assets/images/doc_images/device_dashboard.png';
import devicePlotIMG from '../../assets/images/doc_images/device_plot.png';
import paramsIMG from '../../assets/images/doc_images/server_request.png';

const Documentation = (props) => {
    return(
        <body>
            <main className={styles.Documentation}>                                         
                <h1>Welcome to Poseidon Project - Getting Started</h1>
                <section className={styles.container}>
                    <p>Poseidon Project are managed servers in the cloud. We implements methods to push data into our databases and a dashboard to visualize it, that way provides lightweight methods of carrying out messaging.</p>
                    <p>This is the first stage of the project, we are thinking in implement MQTT protocol also, but for now you can only send request via Http protocol to our server. For user with no experience in MQTT protocols, both approaches are ideal for the “Internet of Things” world of connected devices. </p>
                    <p>Message queues provide a communications protocol, the sender and receiver of the message do not need to interact with the message queue at the same time. </p>
                    <p>Poseidon Project let you focus on the application instead of spending time on scaling the broker or patching the platform. </p>
                    <p>All messages are saved in our database and can be retrieved at any time. </p>
                    <p>No need to configure servers, or dashboards, you get what you need with Poseidon Project.</p>
                    <p>Flexibility and Reliability is our focus, any application or any sensor could be connected to our servers.</p>
                    <hr></hr>
                    <h2>This is Poseidon Homepage:</h2>
                    <img className={styles.full} src={dashboardIMG} alt='HomePage'></img>
                    <hr></hr>
                    <p>First create an account, just need an email, username and password.</p>
                    <p>Now you are ready to connect your devices with Poseidon Server</p>
                    <hr></hr>
                    <p>Add a device to your account.</p>
                    <p>* DeviceID - should be the same as your deviceId of your device</p>
                    <img className={styles.half} src={addDeviceIMG} alt='AddDevice'></img>
                    <hr></hr>
                    <p>Once you add your device now you can visualize you data in the dashboard.</p>
                    <img className={styles.half} src={deviceIMG} alt='DeviceDashboard'></img>                    
                    <hr></hr>                    
                    <p>Plot your data</p>
                    <img className={styles.half} src={devicePlotIMG} alt='PlotDashboard'></img>                                        
                </section>                                                            
                <section className={styles.container}>                     
                    <h1>Connect your device to Poseidon Server</h1>    
                    <p>Just need to send a POST request to this URL:</p>            
                    <p>"http://54.70.120.203:3800/api/homeDevices"</p>            
                    <p>Params:</p>
                    <p>* deviceId: XXXXXXXX (at least 6 and max 8 alphanumeric digits)</p>
                    <p>deviceName: XXXXXXX (at least 6 and max 40 alphanumeric digits)</p>
                    <p>value: XXXXX (int or float)</p>
                    <img className={styles.half} src={paramsIMG} alt='Request'></img>
                </section>
                <footer className={styles.MainFooter}>                        
                    <a href='http://www.freedogstudios.com'>Free Dog Studios &copy; 2021, All Rights Reserved</a>
                </footer>                  
            </main>       
        </body>        
    )
}

export default Documentation;