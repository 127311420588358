export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const DASH_START = 'DASH_START';
export const DASH_SUCCESS = 'DASH_SUCCESS';
//export const DASH_READ = 'DASH_READ';
export const DASH_FAIL = 'DASH_FAIL';
export const DASH_REMOVE = 'DASH_REMOVE';
export const DASH_UPDATE = 'DASH_UPDATE';
export const DASH_USERNAME = 'DASH_USERNAME';

export const PLOTS_RESET = 'PLOTS_RESET';
export const PLOTS_START = 'PLOTS_START';
export const PLOTS_SUCCESS = 'PLOTS_SUCCESS';
export const PLOTS_UPDATE = 'PLOTS_UPDATE'
export const PLOTS_FAIL = 'PLOTS_FAIL';

export const SOCKET_CONNECTION_START = 'SOCKET_CONNECTION_START';
export const SOCKET_CONNECTION_SUCCESS = 'SOCKET_CONNECTION_SUCCESS';
export const SOCKET_CONNECTION_FAIL = 'SOCKET_CONNECTION_FAIL';
export const SOCKET_ON = 'SOCKET_ON';
export const SOCKET_SEND = 'SOCKET_SEND';
export const SOCKET_SUCCESS = 'SOCKET_SUCCESS';
export const SOCKET_FAIL = 'SOCKET_FAIL';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_LISTEN = 'SOCKET_LISTEN';
export const SOCKET_INFO = 'SOCKET_INFO';

export const ADD_DEVICE_START = 'ADD_DEVICE_START';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_FAIL = 'ADD_DEVICE_FAIL';

export const DEVICE_START = 'DEVICE_START';
export const DEVICE_SUCCESS = 'DEVICE_SUCCESS';
export const DEVICE_FAIL = 'DEVICE_FAIL';

export const HOME_PAGE_START = 'HOME_PAGE_START';
export const HOME_PAGE_SUCCESS = 'HOME_PAGE_SUCCESS';
export const HOME_PAGE_FAIL = 'HOME_PAGE_FAIL';
export const HOME_PAGE_USERNAME = 'HOME_PAGE_USERNAME';