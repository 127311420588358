import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import AuxDevice from '../../hoc/Aux_folder/Aux_file';
import axios from '../../axios-dashboard';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import DeviceElements from '../../components/Device/DeviceElements';
import Spinner from '../../components/UI/Spinner/Spinner';
import styles from '../../css/style.css'
import NavigationItem from '../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Modal from '../../components/UI/Modals/ModalUpdate';
import ModalDelete from '../../components/UI/Modals/ModalDelete';

const reload=()=>window.location.reload();

class Devices extends Component{
    constructor(){
        super();
        this.state = {
            show: false,  
            showDelete: false,
            device_id: ''          
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
    }

    showModal = (name) => {        
        this.setState({show: true,
                    device_id: name});        
    }

    hideModal = () => {
        this.setState({show: false, 
            showDelete: false});
        reload();
    }

    deleteDevice = (deldevice) => {
        this.setState({showDelete: true,
                        device_id: deldevice});        
    }

    componentDidMount(){        
        this.props.onDevicesInit();
        this.props.onDeviceRetrieved(localStorage.getItem('email'));        
    }

    render(){
        let deviceElem = (
            <Spinner />
        );
        let modalShow = (
            <div></div>
        );
        let modalDel = (
            <div></div>
        );
        
        if(!this.props.loadDevices){
            if(this.props.deviceLength){
                deviceElem = (
                    <DeviceElements device={this.props.deviceArray}
                                    numberDevices={this.props.deviceLength}
                                    showModal={this.showModal}
                                    handleDelete={this.deleteDevice}/>
                )
            }

        }

        if (this.state.show) {
            modalShow = (
                <Modal show={this.state.show} 
                        handleClose={this.hideModal}                        
                        device_id={this.state.device_id}/>
            )
        }

        if (this.state.showDelete) {
            modalDel = (
                <ModalDelete show={this.state.showDelete} 
                        handleClose={this.hideModal}                        
                        device_id={this.state.device_id}/>
            )
        }

        return(
            <AuxDevice>
                <div className={styles.DeviceContainer}>                    
                    <hr></hr>
                    <hr></hr>                    
                    {deviceElem}
                    {modalShow}
                    {modalDel}
                    <div className={styles.DeviceButton}>                        
                        <NavigationItem link='/addDevice'>Add Device</NavigationItem>
                    </div>                    
                </div>
            </AuxDevice>
        )
    }
}

const mapStateToProps = state => {
    return {
        deviceArray: state.devices.deviceArray,
        deviceLength: state.devices.deviceLength,
        loadDevices: state.devices.loadingDevice,        
    }
}

const mapDispatchProps = dispatch => {
    return{
        onDevicesInit: () => dispatch(actions.devicesStart),
        onDeviceRetrieved: (user) => dispatch(actions.getDeviceItem(user))
    }
}

export default connect(mapStateToProps, mapDispatchProps)(withErrorHandler(Devices,axios));