import React from 'react';
import CanvasJSReact from '../../../assets/canvasjs.react';
import styles from '../../../css/style.css';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dashboardPlots = (props) =>    {    
    return(
        <div className={styles.ContainerPlots}>
            <p>Numero de mediciones: {props.numero}</p>
            <CanvasJSChart options = {props.options}/>
        </div>
    )
}

export default dashboardPlots;