import React from 'react';
import { Redirect } from 'react-router-dom';
import wasserLogo from '../../assets/images/tridente.png';
import style from '../../css/style.css';

const logo = (props) => {
    let logoClick = () => {        
        <Redirect to={'/'}/>
    }
    return (
        <div className={style.Logo}>
            <img src={wasserLogo} onClick={() => logoClick()} alt='wasser'/>
        </div>
    )
}

export default logo;