import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Layout from './hoc/Layout/Layout';
import Documentation from './containers/Documentation/Documentation';
import Dashboard from './containers/Dashboard/Dashboard';
import Devices from './containers/Devices/Devices';
import * as actions from './store/actions/index';

const asyncAuth = asyncComponent(() => {
  return import('./containers/Auth/Auth');
});
const asyncSignUp = asyncComponent(() => {
  return import('./containers/SignUp/SignUp');
});
const asyncDevices = asyncComponent(() =>{
  return import('./containers/AddDevice/AddDevice');
})
const asyncHomePage = asyncComponent(() => {
  return import('./containers/HomePage/HomePage')
})

class App extends Component{
  componentDidMount(){    
    this.props.onTryAuthSignup();  
  }

  render(){
    let token = localStorage.getItem('token'); 
    let routes = (
      <Switch>
        <Route path='/documentation' component={Documentation}/>
        <Route path='/signup' component={asyncSignUp} />
        <Route path='/signin' component={asyncAuth} />        
        <Route path='/' exact component={asyncHomePage} /> 
        <Redirect to='/'/> 
      </Switch>    
    )
    if(this.props.isAuthenticated || token){      
      routes = (
        <Switch>
          <Route path='/documentation' component={Documentation}/>
          <Route path='/addDevice' component={asyncDevices} />
          <Route path='/devices' component={Devices} />
          <Route path='/dashboard' component={Dashboard}/>
          <Route path='/' exact component={asyncHomePage} />  
          <Redirect to='/'/>
        </Switch>    
      )
    }
    return(
      <div>
        <Layout>   
          {routes}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return{
    isAuthenticated: state.auth.token !== null    
  }
}

const mapDispatchToProps = dispatch => {  
  return{
    onTryAuthSignup: () => dispatch(actions.authCheckState())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
