import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    username: null,
    loadHomePage: false,
    totalMeasurements: 0,
    totalDevices: 0,
    errorHomePage: false    
}

const homePageStart = (state, action) => {
    return updateObject(state, {
        loadHomePage: true,
        errorHomePage: false,
    })
}

const homePageSuccess = (state, action) => {
    return updateObject(state, {
        loadHomePage: false,
        errorHomePage: false,
        totalMeasurements: action.totalMeasurements,
        totalDevices: action.totalDevices        
    })
}

const homePageFail = (state, action) => {
    return updateObject(state, {
        loadHomePage: false,
        errorHomePage: true
    })
}

const homePageUsername = (state, action) => {
    return updateObject(state, {
        username: action.username
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.HOME_PAGE_START:
            return homePageStart(state, action);
        case actionTypes.HOME_PAGE_SUCCESS:
            return homePageSuccess(state, action);
        case actionTypes.HOME_PAGE_FAIL:
            return homePageFail(state, action);
        case actionTypes.HOME_PAGE_USERNAME:
            return homePageUsername(state, action);            
        default:
            return state;
    }
}

export default reducer;