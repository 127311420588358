import React from 'react';
import style from '../../../css/style.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigactionItems';

const toolbar = (props) => {
    let attachedClasses = [style.Toolbar];  
    let username = (
        <Logo />
    )
    if(props.username){
        username = (
            <p>{props.username}</p>
        )
    }
    return(        
        <header className={attachedClasses.join(' ')}>                        
            {username}
            {/* <Logo/> */}            
            {/* <div>Iconos diseñados por <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.es/" title="Flaticon">www.flaticon.es</a></div> */}
            <nav>
                <NavigationItems />
            </nav>       
        </header>             
    )
}

export default toolbar;