import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    fromServer: [],
    info: []
}

const socketMessageRecieved = (state, action) => {       
    return updateObject(state, {
        fromServer: action.fromServer
    })
}

const socketMessageAll = (state, action) => {    
    return updateObject(state, {
        info: action.info
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.SOCKET_LISTEN:            
            return socketMessageRecieved(state, action);
        case actionTypes.SOCKET_INFO:
            return socketMessageAll(state, action);
        default:
            return state;
    }
}

export default reducer;