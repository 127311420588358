import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';

export const homePageStart = () => {    
    return{
        type: actionTypes.HOME_PAGE_START
    }
}

export const homePageSuccess = (totalMeasurements, totalDevices) => {    
    return{
        type: actionTypes.HOME_PAGE_SUCCESS,
        totalMeasurements: totalMeasurements,
        totalDevices: totalDevices
    }
}

export const homePageFail = () => {
    return{
        type: actionTypes.HOME_PAGE_FAIL
    }
}

export const homePageUsername = (username) => {
    localStorage.setItem('username', username)
    return{
        type: actionTypes.HOME_PAGE_USERNAME,
        username: username        
    }
}

export const homePageExecutor = () => {    
    return dispatch => {
        dispatch(homePageStart());
        axios.get('/getTotalDispositivos')
            .then(response => {                
                dispatch(homePageSuccess(response.data.TotalMeasurements, response.data.TotalDevices))
            })
            .catch(error => {
                
                dispatch(homePageFail())
            })
    }
}

export const getUsername = (email) => {
    return dispatch => {
        axios.post('/getUserName', {email: email})
            .then(response => {                
                dispatch(homePageUsername(response.data.message[0].username))
            })
            .catch(error => {
                console.log('error')
            })    
    }

}