import createMyWebsocket from 'socket.io-client';
import * as actionTypes from '../actions/actionTypes';

const createMySocketMiddleware = (url) => {
    return storeAPI => {
        let socket = createMyWebsocket(url);        
        socket.on("new-message", (message) => {              
            if(localStorage.getItem('connectID', message)){
                localStorage.clear('connectID')
            }
            localStorage.setItem('connectID', message)                          
            storeAPI.dispatch({
                type : actionTypes.SOCKET_LISTEN,
                fromServer : message
            });
        });

        socket.on("homeDevice-message", (message) => {                                              
            storeAPI.dispatch({
                type : actionTypes.SOCKET_LISTEN,
                fromServer : message
            });
        });        

        socket.on("measurements", (message) => {                                                    
            storeAPI.dispatch({
                type : actionTypes.SOCKET_INFO,
                info : message
            });
        });         

        return next => action => {
            if(action.type === "SEND_WEBSOCKET_MESSAGE") {
                socket.send(action.payload);
                return;
            }
            return next(action);
        }      
    }
}

export default createMySocketMiddleware;