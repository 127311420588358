import React from 'react';
import { NavLink } from 'react-router-dom';

const navigationItem = (props) => (
    <li>
        <NavLink
            to={props.link}
            exact={props.exact}
            activeStyle={{
                fontWeight: "bold",
                color: "#00ddff",
                "border-bottom": "#ff1d58 2px solid"
            }}>{props.children}            
        </NavLink>
    </li>
);

export default navigationItem;